import { ConfigProviderProps } from "antd/es/config-provider";

const fullYear = new Date().getFullYear();
/** @internal */
export function calcReportYears(currentYear:number) : Array<number>{
  return Array.from(
    { length: Math.min(currentYear - 2022,5)},
    (_, i) => currentYear - 1 - i
  );
}
export const REPORT_YEARS = calcReportYears(fullYear);

export const ADD_NEW_REPORT = "add-new-report";

export const ANTD_CONFIG_OVERRIDE: ConfigProviderProps = {
  theme: {
    token: {
      colorPrimary: "#004750",
    },
  },
  button: {
    style: {
      borderRadius: "15px",
      boxShadow: "none",
    },
  },
};
